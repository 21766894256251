import { defaultTheme } from "evergreen-ui";

const intentColors = {
  none: "#2F2E41",
  info: "grey",
  success: "#49BEEF",
  warning: "orange",
  danger: "#DD3B30",
};

export const getButtonColor = (theme, props) => {
  return intentColors[props.intent || "none"] || intentColors.none;
};

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    text: {
      success: "red",
    },
  },
  fontFamilies: {
    ...defaultTheme.fontFamilies,
    ui: '"Montserrat",' + defaultTheme.fontFamilies.ui,
  },
  fontSizes: {
    ...defaultTheme.fontSizes,
    2: "13px",
    body: "13px",
  },
  components: {
    ...defaultTheme.components,
    TextInputField: {
      ...defaultTheme.components.TextInputField,
    },
    Button: {
      ...defaultTheme.components.Button,
      spinnerColor: "hotpink",
      sizes: {
        ...defaultTheme.components.Button.sizes,
        regular: {
          ...defaultTheme.components.Button.sizes.medium,
          height: 32,
          borderRadius: 16,
        },
        large: {
          height: 45,
          lineHeight: "45px",
          minWidth: 32,
          paddingLeft: 32,
          paddingRight: 32,
          fontWeight: "fontWeights.bold",
          textTransform: "uppercase",
          borderRadius: 22, //(theme, props)=>(console.log(props), 22),
        },
      },
      baseStyle: {
        ...defaultTheme.components.Button.baseStyle,
        borderRadius: 0,
      },
      appearances: {
        ...defaultTheme.components.Button.appearances,
        default: {
          ...defaultTheme.components.Button.appearances.default,
          color: getButtonColor,
          border: (theme, props) => {
            switch (props.size) {
              case "large":
                return `2px solid ${getButtonColor(theme, props)}`;
              case "regular":
                return `1px solid ${getButtonColor(theme, props)}`;
              default:
                return defaultTheme.components.Button.appearances.default.border(
                  theme,
                  props
                );
            }
          },
        },
        primary: {
          ...defaultTheme.components.Button.appearances.primary,
          color: "white",
          background: getButtonColor,
          border: (theme, props) => `2px solid ${getButtonColor(theme, props)}`,
          _hover: {
            color: "white",
            backgroundColor: "#49beef",
          },
        },
        primaryWhite: {
          ...defaultTheme.components.Button.appearances.primary,
          color: "white",
          background: getButtonColor,
          textDecoration: "none",
          border: (theme, props) => `2px solid ${getButtonColor(theme, props)}`,
          _hover: {
            color: getButtonColor,
            backgroundColor: "#ffff",
          },
        },
        segment: {
          ...defaultTheme.components.Button.appearances.default,
          height: 45,
          fontSize: 15,
          flex: 1,
        },
      },
    },
    Heading: {
      ...defaultTheme.components.Heading,
      baseStyle: {
        ...defaultTheme.components.Heading.baseStyle,
        color: "#49beef",
        fontFamily: "Montserrat",
        fontWeight: 700,
      },
      sizes: {
        ...defaultTheme.components.Heading.sizes,
        large: {
          fontSize: 20,
        },
        medium: {
          fontSize: 18,
        },
      },
    },
    Input: {
      ...defaultTheme.components.Input,
      baseStyle: {
        ...defaultTheme.components.Input.baseStyle,
        borderRadius: 0,
      },
      sizes: {
        ...defaultTheme.components.Input.sizes,
        large: {
          minHeight: 45,
          fontSize: "13px",
        },
      },
    },
    Select: {
      ...defaultTheme.components.Select,
      baseStyle: {
        ...defaultTheme.components.Select.baseStyle,
        borderRadius: 0,
        background: "white",
      },
    },
    DialogHeader: {
      baseStyle: {
        padding: 22,
      },
    },
    DialogFooter: {
      baseStyle: {
        "&>*": { width: "100%" },
        padding: 22,
      },
    },
    Badge: {
      ...defaultTheme.components.Badge,
      baseStyle: {
        ...defaultTheme.components.Badge.baseStyle,
        textTransform: "none",
        padding: 4,
        paddingLeft: 8,
        paddingRight: 8,
        height: 23,
        fontSize: 10,
      },
    },
  },
};

export default theme;
