import { Pane, Textarea, TextInput, TextInputField } from "evergreen-ui";
import React, { useCallback, useState } from "react";
import ApoFieldLabel from "./ApoFieldLabel";

const textareaProps = {
  is: Textarea,
  style: { maxWidth: "100%" },
};

const ApoTextInput = ({
  label,
  value,
  placeholder,
  defaultValue,
  required,
  size = "large",
  hint,
  type,
  numberProps = {},
  spellCheck,
  isTextarea,
  marginLeft,
  maxWidth,
  minHeight,
  marginBottom = 10,
  onChange,
  onFocus,
  onBlur,
  disabled,
  inputRef,
}) => {
  const [internalValue, setInternalValue] = useState(
    defaultValue !== undefined ? defaultValue : value
  );

  const optionalTextareaProps = isTextarea ? textareaProps : undefined;

  const handleChange = useCallback((e) => {
    let newValue = e.target.value;
    setInternalValue(newValue);
    onChange && onChange(e);
  }, []);

  return (
    <Pane marginLeft={marginLeft}>
      {label && <ApoFieldLabel required={required} value={label} />}
      <TextInput
        ref={inputRef}
        disabled={disabled}
        spellCheck={spellCheck}
        value={value !== undefined ? value : internalValue}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        size={size}
        hint={hint}
        type={type}
        marginBottom={marginBottom}
        width={"100%"}
        maxWidth={maxWidth}
        {...(minHeight !== undefined ? { minHeight } : {})}
        {...optionalTextareaProps}
        {...numberProps}
      />
    </Pane>
  );
};

export default ApoTextInput;
