import ApoTextInput from "./ApoTextInput";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, Button, Pane, Paragraph, TextInput } from "evergreen-ui";

const apiToken =
  "pk.eyJ1IjoiYXBvbWFwc29mdHdhcmVkZXYiLCJhIjoiY2s4dzJlYnM4MDU5cjNlbnc5bjlhbTN1NSJ9.2MVbscPKHtJSJVcLmThq2Q";

const searchRequestHeaders = new Headers();
searchRequestHeaders.append(
  "Content-Type",
  "application/x-www-form-urlencoded"
);
const requestOptions = {
  method: "GET",
  headers: searchRequestHeaders,
  redirect: "follow",
};

const itemToString = (item) => (item ? item.address : "null");

let resolveWaiting;

const searchLocations = async (query) => {
  // Debouncing
  resolveWaiting && resolveWaiting(false);
  const waitingPromise = new Promise((resolve) => {
    resolveWaiting = resolve;
    setTimeout(() => resolve(true), 600);
  });
  if (!(await waitingPromise)) return undefined;
  //

  try {
    let result = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=de&language=de&types=locality%2Cplace%2Caddress%2Cpostcode&access_token=${apiToken}`,
      requestOptions
    );
    result = await result.json();

    const locations = [];

    result.features.map((loc) => {
      const locality =
        loc.context.filter(({ id }) => id.split(".")[0] === "locality")[0]
          ?.text ?? "";
      const obj = {
        address: loc.place_name + ", " + locality ?? "",
        coords: loc.center,
        id: "individual-" + loc.place_name,
      };
      locations.push(obj);
    });
    console.log(locations);
    return locations;
  } catch (error) {
    console.log("error", error);
  }

  return [];
};

const ApoLocationInput = ({
  label,
  required,
  defaultValue,
  onChange,
  placeholder,
  style,
  disabled,
  resetNumber // ToDo: This is AWFUL. Please rebuild this component.
}) => {
  const [value, setValue] = useState(defaultValue ? defaultValue.address : "");
  const [prevValue, setPrevValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(defaultValue);
  const [suggestedLocations, setSuggestedLocations] = useState([]);

  useEffect(() => {
    setValue(defaultValue?.address);
  }, [defaultValue]);

  useEffect(() => {
    if(resetNumber !== null && typeof resetNumber === "number") {
      setValue("");
    }
  }, [resetNumber])

  const inputRef = useRef(null);

  const handleChange = useCallback(async (e) => {
    setLoading(true);
    const query = e.target.value;
    setValue(query);
    setPrevValue(query);

    if (!query) {
      setSuggestedLocations([]);
      return;
    }

    const locations = await searchLocations(query);
    if (!(document.activeElement === inputRef.current) || !locations) return;

    setSuggestedLocations(locations);
    setLoading(false);
  }, []);

  const searchAlternativeOnGoogle = useCallback(async () => {
    alert("Not supported in this demo.")
  }, [prevValue]);

  const handleFocus = useCallback(
    (e) => {
      if (selectedLocation) {
        setSuggestedLocations([selectedLocation]);
      }
    },
    [selectedLocation]
  );

  const handleBlur = useCallback(() => {
    if (!suggestedLocations[0]) {
      setValue("");
    }
    setSelectedLocation(suggestedLocations[0]);
    setSuggestedLocations([]);
  }, [selectedLocation, suggestedLocations]);

  const handleAutocompleteSelect = useCallback(async (obj) => {
    inputRef.current && inputRef.current.blur();
    setSuggestedLocations([]);
    setSelectedLocation(obj);
    onChange && onChange(obj);
  }, []);

  useEffect(() => {
    setValue(selectedLocation ? selectedLocation.address : "");
  }, [selectedLocation]);

  return (
    <Pane pointerEvents={disabled ? "none" : null} style={style}>
      <Paragraph>{label}</Paragraph>
      {value !== "" && !loading && (
        <Button
          isLoading={googleLoading}
          onClick={() => searchAlternativeOnGoogle()}
        >
          Adresse nicht gefunden?
        </Button>
      )}
      <ApoTextInput
        disabled={disabled}
        placeholder={placeholder}
        inputRef={inputRef}
        required={required}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <Autocomplete
        key={suggestedLocations.map((l) => l.address).join() || undefined}
        isOpen={!!suggestedLocations.length}
        items={suggestedLocations}
        onSelect={handleAutocompleteSelect}
        itemToString={itemToString}
        position={"bottom-left"}
      >
        {({ getRef }) => <div ref={getRef} style={{ marginTop: -10 }} />}
      </Autocomplete>
    </Pane>
  );
};

export default ApoLocationInput;
