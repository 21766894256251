import React, {useState} from 'react';
import {Button, CrossIcon, Dialog, Heading, Pane, ThemeContext, toaster} from "evergreen-ui";
import theme from './theme';
import ApoTextInput from './ApoTextInput';
import ApoLocationInput from './ApoLocationInput';
import generateCSV from "./utility/generateCSV";
import axios from "axios";

const {Provider: ThemeProvider} = ThemeContext;

const TaskCreateInterface = (props: { onClose: () => void }) => {
  const [customerFName, setCustomerFName] = useState("");
  const [customerLName, setCustomerLName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [taskLocation, setTaskLocation] = useState<any>(null);
  const [locationResetNumber, setLocationResetNumber] = useState(0);
  const [openAmount, setOpenAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);

    console.log("Saved", taskLocation);
    if(taskLocation === null || typeof taskLocation.address !== "string") {
      alert("Bitte geben Sie eine Lieferadresse an");
      return;
    }

    const locationParticles = taskLocation.address.toString().split(", ");

    if(locationParticles.length < 3) {
      alert("Ungültige Lieferadresse");
      return;
    }

    try {
      const resp = await axios.post(
        `https://europe-west3-apomapdreinull.cloudfunctions.net/api/getCsv?merchant_token=${localStorage.getItem("apiToken")}`,
        generateCSV(
          "",
          customerFName,
          customerLName,
          locationParticles[0],
          locationParticles[1].split(" ")[0],
          locationParticles[1].split(" ")[1] ?? "Unbekannter Ort",
          customerPhone,
          customerEmail,
          openAmount
        ),
        {
          headers: {
            'Content-Type': 'text/csv'
          }
        }
      );

      console.log('Upload Info: ', resp);
      toaster.success("Der Auftrag wurde übermittelt.");
      props.onClose();
    } catch (e) {
      console.error(`Ingest for file failed.`);
      console.error(e);
      toaster.danger("Der Auftrag konnte nicht angelegt werden.");
    }

    setLoading(false);
  }

  // @ts-ignore
  return (
    // @ts-ignore
    <ThemeProvider value={theme}>
      <Dialog
        isShown={true}
        cancelLabel="Abbrechen"
        confirmLabel="Hinzufügen"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscapePress={false}
        hasHeader={false}
        hasFooter={false}
        contentContainerProps={{
          className: "dialog-content-container",
          padding: 0,
        }}
        containerProps={{className: "square-corners"}}
        width="80vw"
      >
        {/*<BlueBar/>*/}
        <CrossIcon
          onClick={props.onClose}
          style={{
            position: "absolute",
            right: 20,
            top: 20,
            cursor: "pointer",
          }}
        />

        {/* Title */}
        <Pane display={"flex"}>
          <Pane
            style={{
              padding: 20,
              paddingBottom: 0,
            }}
          >
            <Heading size={"large"} marginBottom={20}>
              Botendienst
            </Heading>
          </Pane>
          <Pane
            style={{
              flex: 1,
              padding: 20,
              backgroundColor: "#F9FAFC",
            }}
          ></Pane>
        </Pane>

        {/* Form */}
        <Pane display={"flex"} overflow={"scroll"} flex={1}>
          <Pane
            style={{
              width: "90%",
              padding: 20,
            }}
          >
            <Pane
              className={"spaced-h-12-flx1"}
              display={"flex"}
              flexDirection={"row"}
            >
              {/* @ts-ignore */}
              <ApoTextInput
                disabled={false}
                label="Vorname"
                required
                placeholder="Max"
                value={customerFName}
                onChange={(e: any) => setCustomerFName(e.target.value)}
              />

              {/* @ts-ignore */}
              <ApoTextInput
                disabled={false}
                label="Nachname"
                required
                placeholder="Muster"
                value={customerLName}
                onChange={(e: any) => setCustomerLName(e.target.value)}
              />


              {/* @ts-ignore */}
              <ApoTextInput
                label="Betrag"
                required
                // type={'number'}
                // numberProps={{
                //     step: 0.01,
                //     min: 0.0,
                // }}
                value={openAmount}
                onChange={(e: any) => setOpenAmount(e.target.value)}
              />
            </Pane>
            <Pane
              className={"spaced-h-12-flx1"}
              display={"flex"}
              flexDirection={"row"}
            >
              {/* @ts-ignore */}
              <ApoTextInput
                disabled={false}
                label="Telefonnummer"
                // required
                placeholder="0800/666666"
                type={"tel"}
                value={customerPhone}
                onChange={(e: any) => setCustomerPhone(e.target.value)}
              />

              {/* @ts-ignore */}
              <ApoTextInput
                disabled={false}
                label="E-Mail Adresse"
                value={customerEmail}
                type={"email"}
                placeholder="mustermann@mail.de"
                onChange={(e: any) => setCustomerEmail(e.target.value)}
              />
            </Pane>
            <Pane
              className={"spaced-h-12-flx1"}
              display={"flex"}
              flexDirection={"row"}
            >
              {/* @ts-ignore */}
              <ApoLocationInput
                disabled={false}
                label={"Lieferadresse"}
                required
                defaultValue={taskLocation}
                onChange={(e: any) => setTaskLocation(e)}
                resetNumber={locationResetNumber}
              />
            </Pane>
          </Pane>
        </Pane>
        <Pane
          style={{display: "flex", padding: 20, flex: 1}}
          className={"spaced-h-30"}
        >
          <Button
            size={"large"}
            style={{marginRight: "auto"}}
            appearance={"primary"}
            onClick={props.onClose}
          >
            Abbrechen
          </Button>

          <Button
            size={"large"}
            intent={"success"}
            appearance={"primary"}
            isLoading={loading}
            onClick={save}
          >
            Speichern
          </Button>
        </Pane>
      </Dialog>
    </ThemeProvider>
  );
};

export default TaskCreateInterface;
