import React, {useState} from 'react';
import './App.css';
import TaskCreateInterface from "./TaskCreateInterface";
import useKeyboardShortcuts from "./useKeyboardShortcuts";

function App() {
  const [createShown, setCreateShown] = useState<boolean>(false);

  useKeyboardShortcuts([{
    name: "Set API Token",
    desc: "Sets the API Token to use to send the tasks to the API",
    key: "KeyT",
    alt: true,
    action: () => {
      window.localStorage.setItem(
        "apiToken",
        prompt(
          "Bitte gebe den API Token ein. Bitte auf Leerzeichen vor oder nach der Eingabe aufpassen!",
          ""
        ) ?? ""
      )
    }
  }])

  return (
    <div style={{position: "relative", width: "100vw", height: "100vh", overflow: "hidden"}}>
      <img src={"/bg.png"} style={{position: "absolute", top: 0, left: 0, width: "100vw", height: "100vh"}}/>
      <div className={"interactiveOverlay"}
           style={{position: "absolute", top: 0, left: 0, width: "100vw", height: "100vh"}}>
        {createShown && (
          <TaskCreateInterface onClose={() => setCreateShown(false)}/>
        )}
        <button className={"botendienstButton"} onClick={() => setCreateShown(true)}>
          <img src={"/caricon.png"} className={"botendienstAuto"}/>
          <p className={"botendienstText"}>Botendienst</p>
        </button>
      </div>
    </div>
  );
}

export default App;
