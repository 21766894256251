/**
 * A hook to define, manage and automatically (de)activate keyboard shortcuts.
 *
 * © Niklas Schrötler, 2022
 */

import {useEffect, useState} from "react";

export type KeyboardShortcutDefinition = {
  name: string,
  desc: string,
  key: string,
  ctrl?: boolean,
  alt?: boolean,
  shift?: boolean,
  action: () => void
}

export default function useKeyboardShortcuts(definitions: KeyboardShortcutDefinition[]) {
  const keypressHandler = (evt: KeyboardEvent) => {
    for(let definition of definitions) {
      if (
        evt.code === definition.key &&
        evt.ctrlKey === (definition.ctrl ?? false) &&
        evt.altKey === (definition.alt ?? false) &&
        evt.shiftKey === (definition.shift ?? false)
      ) {
        definition.action();
        break;
      }
    }
      console.log(evt.code);
  };

  useEffect(() => {
    document.removeEventListener("keydown", keypressHandler);
    document.addEventListener("keydown", keypressHandler);
  }, []);
}
