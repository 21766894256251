import { Label } from 'evergreen-ui';
import React from 'react';

const ApoFieldLabel = ({value, required})=>{
    return (
        <Label fontSize={'15px'} fontWeight={400} marginBottom={8} display={'block'}>
            {value}{required ? <span style={{ color: '#49beef' }}> *</span> : ''}
        </Label>
    )
}

export default ApoFieldLabel
