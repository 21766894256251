/**
 * Generates an ADG v6 esque CSV
 */
import moment from "moment";

export default function generateCSV(
  anrede: string,
  vorname: string,
  nachname: string,
  strasseUndHausnr: string,
  plz: string,
  ort: string,
  phone: string,
  mail: string,
  openAmount: string
) {
  const jobnr = Math.floor(Math.random() * 900000) + 100000;

  const createdAt = moment().format("DD.MM.YYYY HH:mm:ss");
  const today = moment().format("DD.MM.YYYY");

  return `6;1111111;${jobnr};1111111;in Bearbeitung;${createdAt};Holstein;${today};${today};00:00:00;23:59:00;;;EUR;668;${Math.round(parseFloat(openAmount.replace(",", ".")) * 100)};295;;${anrede};${vorname};${nachname};Wichernheim;Station 1;;;${strasseUndHausnr};;${plz};${ort};DE;${phone};;;${mail};;;;;;;;;;;;;;;;;;;;;0;0;3;-1;17377588;COMIRNATY 30UG/D BUND;1;1;0;-1;11695086;TRESIBA 200 E/ML ILO FLEX;0;2;0;1;06461110;Botendienst;0;0;0`
}
